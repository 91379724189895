<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Remote Technicians/List</h4>
          <div>
            <router-link :to="can('technician-create') ? {name:'appTechnicianCreate'} : '#'">
              <span :title="can('technician-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>

        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect
                    v-model="selectedPagination"
                    class=""
                    :options="paginationList"
                    :close-on-select="true"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                    :allow-empty="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Role</label>
                <VueMultiselect
                    v-model="selectedRole"
                    class=""
                    :options="roleList"
                    :close-on-select="true"
                    placeholder="Select Role"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                    :allow-empty="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>State</label>
                <VueMultiselect
                    v-model="selectedState"
                    :options="stateList"
                    :close-on-select="true"
                    placeholder="Select State"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                    :allow-empty="false"
                />
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect
                    v-model="selectedStatus"
                    :options="statusList"
                    :close-on-select="true"
                    placeholder="Select status"
                    label="name"
                    track-by="value"
                    :show-labels="false"
                    :allow-empty="false"
                />
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                <input
                    v-model="getRemoteTechniciansParams.where_has_user_query"
                    class="form-control search-product-input-element"
                    type="text"
                    placeholder="Search by name or email or phone"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <button type="reset"
                        class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyTechniciansFilter(null)">
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '20%' }">
                      NAME
                    </th>
                    <th class="position-relative" :style="{ width: '25%' }">
                      PHONE & EMAIL
                    </th>
                    <th class="position-relative" :style="{ width: '20%' }">
                      Type
                    </th>
                    <th class="position-relative" :style="{ width: '25%' }">
                      ADDRESS
                    </th>
                    <th class="position-relative" :style="{ width: '10%' }">
                      STATUS
                    </th>
                    <th class="position-relative" :style="{ width: '5%' }">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="remoteTechnician in this.remoteTechnicians" :key="remoteTechnician.id">
                    <td class="small-badge">
                      <span v-if="remoteTechnician.user">
                        {{ `${remoteTechnician.user.first_name} ${remoteTechnician.user.last_name}` }}
                      </span>
                    </td>
                    <td>
                      <div>
                        <strong>Phone: </strong>
                        <span v-if="remoteTechnician.user">{{ remoteTechnician.user.phone_number }}</span>
                      </div>
                      <div>
                        <strong>Email: </strong>
                        <span v-if="remoteTechnician.user">{{ remoteTechnician.user.email }}</span>
                      </div>
                    </td>
                    <td>
                      <span v-if="remoteTechnician.employee && remoteTechnician.employee.type">
                        {{ remoteTechnician.employee.type }}
                      </span>
                    </td>
                    <td>
                        <span v-if="remoteTechnician.employee && remoteTechnician.employee.address">
                          {{
                            `${remoteTechnician.employee.address.street}, ${
                                remoteTechnician.employee.address.suburb
                            }, ${remoteTechnician.employee.address.state.toUpperCase()}, ${
                                remoteTechnician.employee.address.post_code
                            }, ${remoteTechnician.employee.address.country}`
                          }}
                        </span>
                    </td>
                    <td>
                      <div class="form-group">
                        <div class="controls">
                          <div
                              class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                            <div>
                              <input type="checkbox" class="custom-control-input"
                                     @click="toggleStatus(remoteTechnician.id, remoteTechnician.status)"
                                     :checked="remoteTechnician.status === 'Active'"
                                     :id="`remoteTechnicianStatue-${remoteTechnician.id}`">
                              <label class="custom-control-label mr-1"
                                     :for="`remoteTechnicianStatue-${remoteTechnician.id}`">
                              </label>
                            </div>
                            <span class="font-medium-1">{{ remoteTechnician.status }}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-between">
                        <router-link
                            class="text-decoration-none view-edit-delete-icon"
                            style="height: 18px"
                            :title="can('technician-view') ? 'View' : 'View Forbidden'"
                            :to="can('technician-view') ?
                            {
                              name: 'appTechnicianView',
                              params: { id: remoteTechnician.id },
                            } : '#'">
                          <i class="bx bx-show"></i>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination
                    position="right"
                    :pagination-data="paginateLinks"
                    @getClickedPage="applyTechniciansFilter"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout'
import ListPagination from '@/components/backEnd/pagination/ListPagination';
// core packages
import {mapActions, mapGetters} from "vuex";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "RemoteTechnicianList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ListPagination,
  },
  data() {
    return {
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedRole: {
        value: '',
        name: 'Any'
      },
      selectedState: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      getRemoteTechniciansParams: {
        where_has_user_query: '',
        where_has_employee_address_state: '',
        where_has_user_role_id: '',
        status: '',
        with_relation: ['user', 'user.role', 'employee.address', 'technician'],
        order_by_id: 'DESC',
        paginate: 1,
        pagination: '',
        page: ''
      },
      getSettingsParams: {
        type: ['default'],
        key: ['pagination', 'default_state'],
      },
      getRolesParams: {
        type: 2
      },
    }

  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getRemoteTechniciansParams.pagination = selectedPagination.value;
    },
    selectedState(selectedState) {
      this.getRemoteTechniciansParams.where_has_employee_address_state = selectedState.value;
    },
    selectedRole(selectedRole) {
      this.getRemoteTechniciansParams.where_has_user_role_id = selectedRole.value;
    },
    selectedStatus(selectedStatus) {
      this.getRemoteTechniciansParams.status = selectedStatus.value;
    },
  },
  computed: {
    ...mapGetters({
      remoteTechnicians: 'appRemoteTechnicians/remoteTechnicians',
      paginateLinks: 'appRemoteTechnicians/paginateLinks',
    }),

    statusList() {
      return [
        {value: '', name: 'Any'},
        {value: 0, name: 'Inactive'},
        {value: 1, name: 'Active'},
      ];
    },
    paginationList() {
      return [
        {
          value: this.$store.getters['appSettings/settingDefaultPagination'].value,
          name: 'Default'
        },
        {
          value: 25,
          name: '25 Entries'
        },
        {
          value: 50,
          name: '50 Entries'
        },
        {
          value: 100,
          name: '100 Entries'
        }
      ];
    },
    stateList() {
      return [
        {
          value: "",
          name: "Any",
        },
        ...this.$store.getters["appSettings/settingDefaultState"].value,
      ];
    },

    roleList() {
      let activeRoles = this.$store.getters["appRoles/roles"].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName,
        };
      });

      return [
        {
          value: "",
          name: "None",
        },
        ...activeRoles,
      ];
    },


  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getRoles: 'appRoles/getRoles',
      resetRoles: 'appRoles/resetRoles',
      getRemoteTechnicians: 'appRemoteTechnicians/getRemoteTechnicians',
      putRemoteTechnicianOnList: 'appRemoteTechnicians/putRemoteTechnicianOnList',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async getRemoteTechnicianList() {
      await this.getRemoteTechnicians(this.getRemoteTechniciansParams)
          .then(async (response) => {
            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }
          });
    },

    toggleStatus(id, remoteTechnicianStatus) {
      let status = !(remoteTechnicianStatus === 'Active') ? 1 : 0;
      const paramObj = {
        id: id,
        data: {status: status},
      };

      this.putRemoteTechnicianOnList(paramObj).then(response => {
        if (response.status === 200) {
          const toastObj = {
            message: 'Status Updated Successfully',
            type: 'success'
          };
          this.showToastMessage(toastObj);
        }
      });
    },

    async applyTechniciansFilter(pageNumber) {
      this.loader(true);
      this.getRemoteTechniciansParams.page = pageNumber;
      await this.getRemoteTechnicianList();
      this.loader(false);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getRoleList();
    await this.getRemoteTechnicianList();
    await this.loader(false);
  },
}
</script>

<style scoped>

</style>